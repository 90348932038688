<template>
  <el-dialog
    id="InfoDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="600px"
    close-on-press-escape
    append-to-body
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form ref="InfoForm" @submit.native.prevent="onSubmit">
      <el-row>
        <el-col>
          <el-descriptions class="margin-top" :title="model.info" :column="1">
            <el-descriptions-item v-for="(item, index) in this.model.data" :key="index" :label="item.name">
              {{ item.value }}
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "Info",
  data() {
    var that = this;
    return {
      loading: false,
      dialogShow: false,
      title: "Info",
      model: { info: "", data: [] },
    };
  },

  methods: {
    onCommit() {
      this.dialogShow = false;
    },
    onShow() {
      this.loading = false;
    },
  },
  created() {
    bus.$on("forms.info.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.model = _.extend(this.model, data.initModel);
      this.dialogShow = true;
    });
    bus.$on("forms.info.hide", () => {
      this.dialogShow = false;
    });
  },
};
</script>
<style lang="scss">
#InfoDialog {
  .el-dialog {
    max-height: 300px !important;
  }
  .button-in-form {
    margin-bottom: 16px;
  }
}
</style>
